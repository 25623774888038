export default function LogoCloud() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="grid grid-cols-1 items-center gap-x-8 gap-y-16 lg:grid-cols-2">
          <div className="mx-auto w-full max-w-xl lg:mx-0">
            <h2 className="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
              Trusted by Visionary Founders and Teams
            </h2>
            <p className="mt-6 text-lg/8 text-gray-600">
              I’ve partnered with innovative startups and founders from industry
              leaders to build scalable MVPs that bring ideas to life. Whether
              you’re launching your first product or scaling your next big
              thing, you’re in expert hands.
            </p>
          </div>
          <div className="mx-auto grid w-full max-w-xl grid-cols-2 items-center gap-y-12 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:pl-8">
            <img
              alt="SAP"
              src="/assets/sap.svg"
              width={104}
              height={48}
              className="max-h-12 w-full object-contain object-left"
            />
            <img
              alt="Reonic"
              src="/assets/reonic.svg"
              width={105}
              height={48}
              className="max-h-12 w-full object-contain object-left"
            />
            <img
              alt="Google"
              src="/assets/google.svg"
              width={140}
              height={48}
              className="max-h-12 w-full object-contain object-left"
            />
            <img
              alt="Uber"
              src="/assets/uber.svg"
              width={136}
              height={48}
              className="max-h-12 w-full object-contain object-left"
            />
            <img
              alt="Y Combinator"
              src="/assets/ycom.svg"
              width={147}
              height={48}
              className="max-h-12 w-full object-contain object-left"
            />
            <img
              alt="Dropbox"
              src="/assets/dropbox.svg"
              width={147}
              height={30}
              className="max-h-12 w-full object-contain object-left"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

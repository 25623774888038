export default function Header() {
  return (
    <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <p className="text-base/7 font-semibold text-highlight">
          Big Tech expertise, startup agility
        </p>
        <h2 className="mt-2 text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl">
          My work
        </h2>
        <p className="mt-8 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
          Explore my past work, and maybe even take some inspiration for
          yourself.
        </p>
      </div>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <a
          href="https://cal.com/fliellerjulian/30min"
          className="rounded-md bg-highlight px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-highlight"
        >
          Book your first call for free
        </a>
        <a
          href="https://tally.so/r/31bY4W"
          className="text-sm/6 font-semibold text-gray-900 hover:text-gray-500"
        >
          Contact me <span aria-hidden="true">→</span>
        </a>
      </div>
    </div>
  );
}

import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";

const features = [
  {
    name: "Launch in weeks",
    description:
      "Get your MVP ready to go live in just 2-4 weeks. I prioritize speed without compromising on quality, so you can validate your idea faster.",
    icon: CloudArrowUpIcon,
  },
  {
    name: "Modern technology stack",
    description:
      "Your MVP will be built using cutting-edge technologies, ensuring it’s fast, stable, and ready to scale as your business grows.",
    icon: LockClosedIcon,
  },
  {
    name: "Streamlined process",
    description:
      "I take care of everything—from web or mobile application development to landing pages—streamlined for efficiency and tailored to your vision.",
    icon: ArrowPathIcon,
  },
  {
    name: "Built for security and scalability",
    description:
      "Your product is designed with scalability and security in mind, so it grows seamlessly as you gain traction in the market.",
    icon: FingerPrintIcon,
  },
];

export default function Features() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base/7 font-semibold text-highlight">
            Build and launch with confidence
          </h2>
          <p className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl lg:text-balance">
            Everything you need to launch your startup
          </p>
          <p className="mt-6 text-lg/8 text-gray-600">
            From initial idea to market-ready product, I deliver high-quality
            MVPs tailored to your vision. Fast, scalable, and worry-free
            development—all in one package.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base/7 font-semibold text-gray-900">
                  <div className="absolute left-0 top-0 flex size-10 items-center justify-center rounded-lg bg-highlight">
                    <feature.icon
                      aria-hidden="true"
                      className="size-6 text-white"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base/7 text-gray-600">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

export default function Team() {
  return (
    <div className="bg-white py-24 md:py-32" id="about">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {/* New Heading */}
        <h2 className="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
          Meet the Founder
        </h2>

        {/* Founder Profile */}
        <div className="mt-12 flex flex-col items-center gap-10 sm:flex-row">
          <img
            alt="Julian Flieller"
            src="/assets/Julian_Flieller.jpg" // Replace with your image path
            className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover"
          />
          <div className="max-w-xl text-center sm:text-left">
            <h3 className="text-lg/8 font-semibold tracking-tight text-gray-900">
              Julian Flieller
            </h3>
            <p className="text-base/7 text-gray-600">Founder / Lead Engineer</p>
            <p className="mt-6 text-base/7 text-gray-600">
              From scaling solutions at Big Tech companies to launching and
              growing startups, I bring years of experience in building
              high-quality, scalable MVPs. My passion lies in turning ideas into
              market-ready products using modern technologies, efficient
              workflows, and a founder-first approach.
            </p>
            <a
              href="/portfolio"
              className="mt-5 block w-36 rounded-md border border-highlight px-3 py-2 text-center text-sm font-semibold text-highlight hover:bg-highlight/10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-highlight"
            >
              My Portfolio
            </a>

            <ul
              role="list"
              className="mt-6 flex justify-center sm:justify-start gap-x-6"
            >
              <li>
                <a
                  href="https://twitter.com/@fliellerjulian"
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">X</span>
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                    className="size-5"
                  >
                    <path d="M11.4678 8.77491L17.2961 2H15.915L10.8543 7.88256L6.81232 2H2.15039L8.26263 10.8955L2.15039 18H3.53159L8.87581 11.7878L13.1444 18H17.8063L11.4675 8.77491H11.4678ZM9.57608 10.9738L8.95678 10.0881L4.02925 3.03974H6.15068L10.1273 8.72795L10.7466 9.61374L15.9156 17.0075H13.7942L9.57608 10.9742V10.9738Z" />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://linkedin.com/in/julian-flieller"
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">LinkedIn</span>
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                    className="size-5"
                  >
                    <path
                      d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                      clipRule="evenodd"
                      fillRule="evenodd"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

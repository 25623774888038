const posts = [
  {
    id: 1,
    title: "HypeApp",
    href: "https://apps.apple.com/de/app/hypeapp-sneakers/id1661562762",
    description:
      "A mobile application designed to connect sneaker enthusiasts with the latest drops, exclusive deals, and a curated marketplace.",
    imageUrl: "assets/hypeapp.png",
    category: { title: "Mobile App", href: "#" },
  },
  {
    id: 2,
    title: "CarSpy",
    href: "https://www.car-spy.com",
    description:
      "Tracking all car portals in real time, notifying you when cars are being offered under market value.",
    imageUrl: "assets/carspy.png",
    category: { title: "Mobile App / Web App", href: "#" },
  },
  {
    id: 3,
    title: "SAP CAP - Developer Dashboard Node.js Runtime",
    href: "https://cap.cloud.sap/docs/",
    description:
      "A web application that provides a developer-friendly interface to manage and monitor your SAP Cloud Application Programming Model services.",
    imageUrl: "assets/sapcap.jpg",
    category: { title: "Web App", href: "#" },
  },
  {
    id: 4,
    title: "Reonic (Series A Startup)",
    href: "https://reonic.de/",
    description:
      "Mobile application for solar installers, facilitating better calendar management and appointment handling",
    imageUrl: "assets/reonicapp.png",
    category: { title: "Mobile App", href: "#" },
  },
  {
    id: 5,
    title: "PinFold",
    href: "https://pinfold.me",
    description:
      "Chrome Extension that seemslessly integrates with the ChatGPT UI to help you organize your conversations with pins and folders",
    imageUrl: "assets/pinfold.png",
    category: { title: "Chrome Extension", href: "#" },
  },
  {
    id: 5,
    title: "getwaway.direct",
    href: "https://getaway.direct",
    description:
      "Chrome Extension that helps you find direct booking links on airbnb to save cleaning and service fees",
    imageUrl: "assets/getaway.png",
    category: { title: "Chrome Extension", href: "#" },
  },
  {
    id: 5,
    title: "TwInbox",
    href: "https://twinbox.me",
    description:
      "Chrome Extension that seemslessly integrates with the X UI to help you organize your conversations with folders",
    imageUrl: "assets/twinbox.png",
    category: { title: "Chrome Extension", href: "#" },
  },
];

export default function MyWork() {
  return (
    <div className="bg-white py-12 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          {posts.map((post) => (
            <article
              key={post.id}
              className="flex flex-col items-start justify-between"
            >
              <div className="relative w-full">
                <img
                  alt=""
                  src={post.imageUrl}
                  className="aspect-video w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="max-w-xl">
                <div className="mt-8 flex items-center gap-x-4 text-xs">
                  <a
                    href={post.category.href}
                    className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                  >
                    {post.category.title}
                  </a>
                </div>
                <div className="group relative">
                  <h3 className="mt-3 text-lg/6 font-semibold text-gray-900 group-hover:text-gray-600">
                    <a href={post.href} target="_blank">
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                  <p className="mt-5 line-clamp-3 text-sm/6 text-gray-600">
                    {post.description}
                  </p>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
}

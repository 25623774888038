import Hero from "../components/Hero";
import LogoCloud from "../components/LogoCloud";
import Features from "../components/Features";
import Team from "../components/Team";
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";
import Pricing from "../components/Pricing";
import Faq from "../components/Faq";

export default function Home() {
  return (
    <>
      <Hero />
      <LogoCloud />
      <Features />
      <Pricing />
      <Testimonials />
      <Team />
      <Faq />
      <Footer />
    </>
  );
}

import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";

const faqs = [
  {
    question: "What´s an MVP?",
    answer:
      "An MVP (Minimum Viable Product) is a streamlined version of  your product designed to excel at 1-2 core features. It’s crafted to test market viability and gather user feedback quickly.",
  },
  {
    question: "What happens if I don’t like the final product?",
    answer:
      "My process is built to avoid this. You’ll receive regular updates and previews throughout the development, giving you the opportunity to provide feedback along the way. Additionally, two rounds of revisions are included in the package to ensure the final product meets your expectations.",
  },
  {
    question: "How long will it take to build my MVP?",
    answer:
      "I specialize in rapid development, so your MVP will typically be ready in 2-4 weeks, depending on the complexity of your project. I prioritize efficiency without compromising quality, and you’ll receive regular updates to keep you in the loop.",
  },
  {
    question: "Do you take clients worldwide?",
    answer:
      "Yes! I work with clients from all over the world. No matter where you’re located, I can help you bring your MVP to life with clear communication, regular updates, and seamless collaboration across time zones.",
  },
  {
    question: "Can I request additional features after the project starts?",
    answer:
      "Absolutely! However, any features not included in the initial scope will be considered an add-on and may affect the timeline and cost. I’ll work closely with you to define priorities and keep everything aligned with your goals.",
  },
];

export default function FAQ() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
            Frequently asked questions
          </h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure key={faq.question} as="div" className="pt-6">
                <dt>
                  <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                    <span className="text-base/7 font-semibold">
                      {faq.question}
                    </span>
                    <span className="ml-6 flex h-7 items-center">
                      <PlusSmallIcon
                        aria-hidden="true"
                        className="size-6 group-data-[open]:hidden"
                      />
                      <MinusSmallIcon
                        aria-hidden="true"
                        className="size-6 [.group:not([data-open])_&]:hidden"
                      />
                    </span>
                  </DisclosureButton>
                </dt>
                <DisclosurePanel as="dd" className="mt-2 pr-12">
                  <p className="text-base/7 text-gray-600">{faq.answer}</p>
                </DisclosurePanel>
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

const testimonials = [
  {
    body: "It was a pleasure working with Julian, who shipped my MVP in just 1 week. Communication is smooth, he's very professional and a joy to work with",
    author: {
      name: "Mauro Junca Romero",
      company: "Mailcrafter AI",
      imageUrl: "../assets/mauro.png",
    },
  },
  {
    body: "Julian is a true professional. He built our MVP in just 3 weeks, and the quality exceeded our expectations. I highly recommend him.",
    author: {
      name: "Michael Kater",
      company: "Kater Mice & Tours ",
      imageUrl: "../assets/kater.png",
    },
  },
  {
    body: "Our MVP was live in just three weeks, and the quality exceeded our expectations. The process was seamless from start to finish.",
    author: {
      name: "Michael Soumbatov",
      company: "TL Dashboards",
      imageUrl: "../assets/michael.png",
    },
  },
  {
    body: "Julian understood our vision immediately and delivered a fully functional MVP in record time. His experience in both Big Tech and startups really shows in their work.",
    author: {
      name: "Berke Aymergen",
      company: "F&A Solutions GmbH",
      imageUrl: "../assets/berke.png",
    },
  },
  {
    body: "Julian built our Mobile App MVP in just 2 weeks, delivering a flawless user experience. He's a true professional and a pleasure to work with.",
    author: {
      name: "Ivar",
      company: "Stashed Sneakers",
      imageUrl: "../assets/avatar.png",
    },
  },
  {
    body: "We were able to validate our idea quickly and attract investors. The landing page they built is modern, scalable, and polished.",
    author: {
      name: "Eliz Telli",
      company: "thinkbiggercalendar",
      imageUrl: "../assets/eliz.png",
    },
  },
  {
    body: "Julian makes everything feel more manageable with his clear communication, proactive updates, and attention to detail. The entire process was seamless, and the end result exceeded my expectations.",
    author: {
      name: "Tristan Menzinger",
      company: "Reonic",
      imageUrl: "../assets/tristan.png",
    },
  },
];

export default function Example() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-base/7 font-semibold text-highlight">
            Testimonials
          </h2>
          <p className="mt-2 text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
            What Our Clients Say About Us
          </p>
        </div>
        <div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
          <div className="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3">
            {testimonials.map((testimonial) => (
              <div
                key={testimonial.author.company}
                className="pt-8 sm:inline-block sm:w-full sm:px-4"
              >
                <figure className="rounded-2xl bg-gray-50 p-8 text-sm/6">
                  <blockquote className="text-gray-900">
                    <p>{`“${testimonial.body}”`}</p>
                  </blockquote>
                  <figcaption className="mt-6 flex items-center gap-x-4">
                    <img
                      alt=""
                      src={testimonial.author.imageUrl}
                      className="size-10 rounded-full bg-gray-50"
                    />
                    <div>
                      <div className="font-semibold text-gray-900">
                        {testimonial.author.name}
                      </div>
                      <div className="text-gray-600">{`Founder of ${testimonial.author.company}`}</div>
                    </div>
                  </figcaption>
                </figure>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

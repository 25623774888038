import Header from "../components/Header";
import MyWork from "../components/MyWork";
import Footer from "../components/Footer";

function Portfolio() {
  return (
    <>
      <Header />
      <MyWork />
      <Footer />
    </>
  );
}

export default Portfolio;

export default function Footer() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
        <h2 className="max-w-2xl text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
          Ready to start your startup journey?
        </h2>
        <p className="mt-6 text-base/7 text-gray-600">
          You'll receive a response within 24 hours of submitting the form. If
          your project is a good fit, I'll schedule a call to discuss the
          details and work process.
        </p>
        <div className="mt-10 flex items-center gap-x-6">
          <a
            href="https://cal.com/fliellerjulian/30min"
            className="rounded-md bg-highlight px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-highlight"
          >
            Book your first call for free
          </a>
          <a
            href="https://tally.so/r/31bY4W"
            className="text-sm/6 font-semibold text-gray-900 hover:text-gray-500"
          >
            Contact me<span aria-hidden="true">→</span>
          </a>
        </div>
      </div>
    </div>
  );
}
